import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeSliderSwitch,
    placeRightText,
} from '../Utils';
const Boxes = {
    box1: function () {
      JXG.Options.point.showInfoBox=false;
      JXG.Options.point.highlight=false;
      JXG.Options.text.highlight=false;
      JXG.Options.text.fixed=true;
      JXG.Options.curve.highlight=false;
      JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
      var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-7, 8, 7, -6],
          keepaspectratio: true, axis:true, ticks:{visible:false},
          grid:true, showCopyright:false, showNavigation:false,
          pan:{enabled:false}, zoom:{enabled:false}});
      brd1.suspendUpdate();
      //Make Responsive
      makeResponsive(brd1);
      //Place Title
      placeTitle(brd1, 'First Order Ordinary Differential Equation', '');
      //
      var f;
      //var sinp = placeInput(brd1, 4, 3.5);
      //var sinp = brd1.create('input', [4, 3.5, ' ' , ''], {cssStyle:'width:5%', fontSize:function(){return 20*brd1.canvasHeight/800}, fixed:true});//
      var go =placeImage(brd1, '/assets/go.svg', 0.5, 5., 1.0,0);
      go.on('down', function(){doIt()});
      //var txt = brd1.create('text', [-4.5, 1, function(){return 'Initial condition: <br> ( ' + P.X().toFixed(2) + ' , ' + P.Y().toFixed(2) + ' )'}],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
      //Function
      var doIt = function()
      {
        let snip = brd1.jc.snippet(document.getElementById("odeinput").value, true, 'x');
        f = function(x)
         {
            return [snip(x)];
         }
         brd1.update();
      }
      //Input
      var inp = brd1.create('text', [-4, 5.5, '<form><input type="text" id="odeinput" value="x"></form>'],
      { fixed:true,
        fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)},
        cssStyle: 'fontFamily:Oswald;background-color:#008CBA;border: 1px solid black;border-radius:3.5px;'
      });
      //Point
      var P = brd1.create('point',[0,0], {name:'Drag Me!', snapToGrid:true, strokeColor: 'black', fillColor: '#5B43FF', size:5, withLabel:true, fixed:false, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      //Text
      brd1.create('text', [-5, 5.5, 'y\'(x) = '],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/800.)}},);
      //brd1.create('text', [-4.5, 5.35, 'dx'],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(13*brd1.canvasWidth/500.)}},);
      //brd1.create('text', [-4.2, 5.5, '='],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/800.)}},);
//var txt =
      brd1.create('text', [-5, 4.5, function(){return 'y(x) = ∫ (' + document.getElementById("odeinput").value + ') dx = f(x)'}],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/800.)}},);
//
      brd1.create('text', [-5, 3.5, 'Bounding Condition: '],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
      //brd1.create('text', [-4.5, 3, function(){return 'x =' + P.X().toFixed(2)}],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
      brd1.create('text', [-5, 2.5, function(){return 'y(x='+ P.X().toFixed(2)+')='+ P.Y().toFixed(2)}],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/800.)}});

      brd1.create('text', [4.5, -0.25, 'x'],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
      brd1.create('text', [-0.25, 6.5, 'y'],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
//
      //Move
     var odeR = function ()
      {
         return JXG.Math.Numerics.rungeKutta('heun', [P.Y()], [P.X(), P.X()+5], 200, f);
      }
      var odeL = function ()
      {
         return JXG.Math.Numerics.rungeKutta('heun', [P.Y()], [P.X(), P.X()-5], 200, f);
      }
      //Curves
      doIt();
      var g1 = brd1.create('curve', [[0],[0]], {strokeColor:'red', strokeWidth:4});
      var g2 = brd1.create('curve', [[0],[0]], {strokeColor:'red', strokeWidth:4});
//
      g1.updateDataArray = function()
      {
          let data = odeR();
          let h = 10/200;
          let i;
          this.dataX = [];
          this.dataY = [];
          for(i=0; i<data.length; i++)
          {
              this.dataX[i] = P.X()+i*h;
              this.dataY[i] = data[i][0];
          }
      };
      g2.updateDataArray = function()
      {
          let data = odeL();
          let h = 10/200;
          let i;
          this.dataX = [];
          this.dataY = [];
          for(i=0; i<data.length; i++)
          {
              this.dataX[i] = P.X()-i*h;
              this.dataY[i] = data[i][0];
          }
      };
      brd1.unsuspendUpdate();
      ///////////////////////////////////////////////
    /*  let magicGraph = document.querySelector("#jxgbox1");
      let inputFields = magicGraph.querySelectorAll("input");
      var onEachPress = function(event) { ini(); vis=0 }
      inputFields.forEach(function(field) {
        field.addEventListener('input', onEachPress);});
    */
      ////////////////////////////////////
    },
}
export default Boxes;
