<template>
  <div>
    <h3 ref="intro">
      Ordinary Differential Equation
    </h3>
    <p>
      An ordinary differential equation (or simply ODE) is an equation of the form:
      $$y^{(n)}(x) = g(x)$$
      where \(y^{(n)}(x)\) is the \(n^{th}\) derivative of \(y(x)\) w.r.t. \(x\), i.e.,
      $$y^{(n)}(x) = \frac{d^ny(x)}{dx^n}$$.
    </p>
    <ul class="a">
      <li> <h5> First Order Ordinary Differential Equation </h5> </li>
      <p>
        First order ordinary differential equation is a special form of ordinary differential equation that involve first order derivative. First order ordinary differential equations are
        also known as linear ordinary differential equations.
        $$y'(x) = g(x)$$
      </p>
      <li> <h5> General Solution of a Linear Ordinary Differential Equation </h5> </li>
      <p>
        The general solution of a linear ODE is given as:
        $$y(x) =  \int g(x) dx + c$$
        where \(c\) is the integration constant, and is determined by using boundary conditions.
      </p>
      <li> <h5> Particular Solution of a Linear Ordinary Differential Equation </h5> </li>
      <p>
        The general solution of a linear ODE is given as:
        $$y(x) =  \int g(x) dx + c$$
        Let's say the boundary condition is \(y(x_0) = y_0\).
        Using this boundary condition, we obtain the particular solution as:
        $$y(x) -y_0 = \int g(x) dx $$
      </p>
    </ul>
    <h3 ref="pg">
      MagicGraph | Linear ODE
    </h3>
    <p> In this MagicGraph, you will learn about solution of a linear ordinary differential equation and boundary condition. </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'ODE',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Linear ODE';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'ODE', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Linear ODE',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about Thales Theorem'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
